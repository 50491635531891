// Used for post unconscious bias
// Horizontal gallery showing up to 5 images
.horizontal-gallery-5 {
  display: flex;
  flex-flow: row;
  margin-bottom: $spacing-unit;

  img {
    max-width: 18%;
    max-height: 150px;
    padding: 1%;
  
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}


// Custom widget linking back to blog
// Plonked at the top of posts
.back-to-blog {
  border: 2px solid $grey-color-light;
  border-radius: 6px;
  padding:3px;

  a {
    color: $grey-color;
  }
}


// Add a background to make my architecture SVGs 'pop' a bit
.technical-diagram {
  background: lightgoldenrodyellow;
  padding: 3px;
}

.header-photo > img {
  filter: drop-shadow(2px 4px 6px gray);
  border-radius: 50%;
}

.header-caption {
  text-align: center;
  font-style: italic;
}