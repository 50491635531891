// Colours
$terminal-background: #000;
$terminal-line-highlight: rgba(3, 50, 15, 0.6);
// Sizes
$terminal-header-height: 30px;
$terminal-header-roundness-radius: 10px;

#terminal-body {
  height: 200px;
  padding-top: 2px;
  border: 1px solid #000;
  background-color: $terminal-background;
  overflow-y: scroll;
}

#terminal-container {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  margin-bottom: $spacing-unit;
  margin-top: $spacing-unit;
  padding-right: 10px;
  padding-left: 10px;
  font-family: "Ubuntu Mono", monospace;
}

#terminal-header {
  height: $terminal-header-height;
  border-top-left-radius: $terminal-header-roundness-radius;
  border-top-right-radius: $terminal-header-roundness-radius;
  border-bottom: 1px solid $grey-color-light;
  background-color: $grey-color-dark;
  color: whitesmoke;
  font-weight: bold;
  text-align: center;
}

#terminal-title {
  line-height: $terminal-header-height;
  vertical-align: middle;
  text-shadow: 0 0 2px black;
}

.terminal-line {
  line-height: 1.2;
  color: $grey-color-light;
}

.terminal-line-example {
  background: $terminal-line-highlight;
}

.terminal-prompt {
  padding-left: 2px;
}

input.terminal-command {
  line-height: 1.2;
  max-width: 100%;
  background-color: transparent;
  border: 0;
  outline: none;
  font-family: "Ubuntu Mono", monospace;
  font-size: inherit;

  // Dirty trick to hide the default blinking caret but keep text visible
  // We are adding a fake 'terminal-style' caret in other devious ways
  color: transparent;
  text-shadow: 0 0 0 $grey-color-light;
}

input.terminal-command:focus + #terminal-cursor {
  animation: blink 1s step-start 0s infinite;
  -webkit-animation: blink 1s step-start 0s infinite;
}

.terminal-output {
  color: $highlight-color;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}
