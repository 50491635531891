.cv-container {
  display: grid;

  @media (max-width: $laptop-size) {
    grid-template-areas: 
      "cv-header"
      "cv-content"
      "cv-footer";
    grid-template-columns: 1fr;
  }
  
  @media (min-width: $laptop-size) {
    grid-template-areas: 
      "cv-header cv-header"
      "cv-left-sidebar cv-content"
      "cv-footer cv-footer";
    grid-template-columns: 200px 1fr;
  }

  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;

  > .cv-header {
    grid-area: cv-header;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-bottom: 1px solid $grey-color-light;

    > .executive-summary {
      max-width: 80%;
    }
  }

  > .cv-content {
    grid-area: cv-content;
    padding: 20px;
  }

  > .cv-left-sidebar {
    grid-area: cv-left-sidebar;
    display: flex;
    flex-flow: column;
    align-items: center;

    @media (max-width: $laptop-size) {
      display: none;
    }
  }

  > .cv-footer {
    grid-area: cv-footer;
    display: flex;
    border-top: 1px solid $grey-color-light;
    padding-top: $spacing-unit;
    
    > .cv-footer-content {
      margin: auto;
      display: flex;
      flex-flow: column;
      max-width: 80%;
    }
  }
}

.cv-item-summary {
  text-align: justify;
}

.cv-item {
  @extend .modal-content;
  width: 60%;
  min-width: $content-min-width;
  
  > .cv-item-title {
    margin-bottom: 0px;
    text-align: center;
  }

  > .cv-item-position {
    color: $grey-color;
  }
}

.cv-item-contract {
  display: flex;
  padding-top: $spacing-unit / 2;
  border-top: $grey-color solid 1px;

  @media (max-width: $laptop-size) {
    flex-flow: column;
  }
  
  &:before {
    display: inherit;
    content: attr(contract-position-title);
    text-align: left;
    text-transform: uppercase;
    color: $grey-color;
    font-weight: bold;

    // On the laptop, this will display to the left and requires some wrapping
    // On mobile, it sits above (due to flex-flow: column), so this isn't needed
    @media (min-width: $laptop-size) {
      align-items: center;
      max-width: 110px;
      min-width: 110px;
    }
  }

  > ul > li {
    margin-top: 5px;
  }
}

.cv-link {
  @extend a;
  background: white;
  padding: 1px;
  border: 1px solid darken($highlight-color, 15%);
  border-radius: 5px;
}